import BigNumber from 'bignumber.js';
import { IEstimatedUsdFeesPerDayResponse } from '../hooks/yldr/useEstimatedUsdFeesPerDayQuery';
import { IPositionPoolData } from '../types/pools';

export const calculateEstimateYearlyFees = (pool: IPositionPoolData | IEstimatedUsdFeesPerDayResponse, liquidity: BigNumber) => {
  return pool.estimated_usd_fees_per_day && pool.liquidity && !liquidity.isNaN() && !liquidity.isZero()
    ? BigNumber(pool.estimated_usd_fees_per_day)
      .multipliedBy(365)
      .multipliedBy(liquidity)
      .div(BigNumber(pool.liquidity).plus(liquidity))
    : BigNumber(0);
}
