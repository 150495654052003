import React from 'react';
import RocketIcon from '/public/icons/rocket.svg';
import { Box, Divider, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { MIN_LEVERAGE } from '../LeveragePosition/constants';
import { DetailsNumberLine } from './TxModalDetails';
import { displayRangeValue } from '../../../utils/displayRangeValue';

export interface IPositionWidgetProps {
  totalAmountUsd: number;
  amountToken0: number;
  amountToken1: number;
  amountUsdToken0: number;
  amountUsdToken1: number;
  symbolToken0: string;
  symbolToken1: string;
  outOfRange?: boolean;
  estNetApr?: number;
  estApr?: number;
  revenueFee?: number;
  borrowApy?: number;
  leverage?: number;
  sx?: SxProps<Theme>;
}

export const PositionWidget = ({
  totalAmountUsd,
  symbolToken0,
  symbolToken1,
  amountToken0,
  amountToken1,
  amountUsdToken0,
  amountUsdToken1,
  outOfRange,
  estNetApr,
  estApr = 0,
  borrowApy = 0,
  revenueFee = 0,
  leverage = MIN_LEVERAGE,
  sx,
}: IPositionWidgetProps) => {
  const percentToken0 = amountUsdToken0 ? 100 / (totalAmountUsd / amountUsdToken0) : 0;
  const percentToken1 = amountUsdToken1 ? 100 / (totalAmountUsd / amountUsdToken1) : 0;
  const displayEstNetApr = outOfRange && leverage === MIN_LEVERAGE || !estNetApr ? 0 : estNetApr;
  const rocket = (estNetApr || 0) > 0 && leverage > MIN_LEVERAGE && !outOfRange;

  return (
    <Box sx={{
      pt: 4,
      pb: 4,
      pl: 5,
      pr: 5,
      border: (theme) => `1px solid ${theme.palette.divider}`,
      backgroundColor: '#1F1F23',
      borderRadius: '8px',
      ...sx,
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
        <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
          ${totalAmountUsd.toFixed(2)}
        </Typography>
        {estNetApr !== undefined && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 1 }}>
            <Typography variant="main12" color="text.secondary">
              Estimated Net APR
            </Typography>
            {rocket && (
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
                ['> svg']: {
                  height: '20px',
                  width: '20px',
                }
              }}>
                <RocketIcon />
                <Tooltip
                  title={(
                    <>
                      <DetailsNumberLine
                        description="Estimated APR"
                        value={estApr / 100}
                        percent={true}
                      />
                      <DetailsNumberLine
                        description="Total Borrow APY"
                        value={borrowApy ? `-${borrowApy / 100}` : 0}
                        percent={true}
                      />
                      <DetailsNumberLine
                        description="Protocol Fee"
                        value={revenueFee ? `-${revenueFee}` : 0}
                        percent={true}
                      />
                      <Divider sx={{ mb: 1.5 }} />
                      <DetailsNumberLine
                        description="Estimated Net APR"
                        value={estNetApr / 100}
                        percent={true}
                      />
                    </>
                  )}
                  placement="bottom-end"
                  arrow
                >
                  <Typography sx={{
                    mb: '-2px',
                    fontSize: '20px',
                    fontWeight: 600,
                    lineHeight: '110%',
                    borderBottom: '2px solid #AFF113',
                    color: '#AFF113',
                  }}>
                    {displayEstNetApr?.toFixed(2) || 0}%
                  </Typography>
                </Tooltip>
              </Box>
            )}
            {!rocket && (
              <Typography sx={{ fontSize: '20px', fontWeight: 600, lineHeight: '110%' }}>
                {displayEstNetApr?.toFixed(2) || 0}%
              </Typography>
            )}
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', mt: 3 }}>
        {percentToken0 > 1 && (
          <Box
            sx={{
              width: `${percentToken0}%`,
              height: '10px', mr: '1px',
              backgroundColor: '#1DC99B',
              borderRadius: percentToken0 >= 99 ? '4px' : '4px 0 0 4px',
            }}
          />
        )}
        {percentToken1 > 1 && (
          <Box
            sx={{
              width: `${percentToken1}%`,
              height: '10px',
              ml: '1px',
              backgroundColor:'#32B4FF',
              borderRadius: percentToken1 >= 99 ? '4px' : '0 4px 4px 0',
            }}
          />
        )}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Box sx={{ width: '10px', height: '10px', mr: 1, borderRadius: '50%', backgroundColor: '#1DC99B' }} />
        <Typography sx={{ mr: 3 }}>{displayRangeValue(amountToken0)} {symbolToken0}</Typography>
        <Box sx={{ width: '10px', height: '10px', mr: 1, borderRadius: '50%', backgroundColor: '#32B4FF' }} />
        <Typography>{displayRangeValue(amountToken1)} {symbolToken1}</Typography>
      </Box>
    </Box>
  )
};
